<template>
    <div class="nav">
        <div class="center">
            <TitleComponent :text='text'></TitleComponent>
            <div class="card">
                <div class="t1">
                    <div>
                        <p class="p1">鲁班云服 - 强大的系统支持</p>
                        <p class="p2 p21">聚焦核心领域，提供全流程解决方案</p>
                        <img src="../../../../assets/img/home/more.png" alt="">
                    </div>
                </div>
                <Mask class="mask1" :data = data[0]></Mask>
                <div class="t2">
                    <div>
                        <p class="p1">中台数据中心-客户沉淀</p>
                        <p class="p2 p22">实现多渠道融合及基于大数据的精细化运营管理</p>
                        <img src="../../../../assets/img/home/more.png" alt="">
                    </div>
                </div>
                <Mask class="mask2" :data = data[1]></Mask>
                <div class="t3">
                    <div>
                        <p class="p1">前台应用-展业出单系统</p>
                        <p class="p2 p23">沉淀业务能力、赋能前端业务</p>
                        <img src="../../../../assets/img/home/more.png" alt="">
                    </div>
                </div>
                <Mask class="mask3" :data = data[2]></Mask>
            </div>
        </div>
    </div>
    
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
import Mask from '../../../../components/Mask.vue'
const text = '我们使用技术赋能数字化保险，为企业提供整套的服务'
const data = [{
    icon:'kej',
    title:'科技驱动',
    text:'秦保自主研发saas云平台，运用科技数字化手段为保险销售全流程提供“科技+服务”支撑，为保险业务提供端对端一站式解决方案，从而助力展业。'
},
{
    icon:'shujuyunying',
    title:'数据运营',
    text:'当前保险公司营销渠道互联网化已不仅局限于网络直销平台建设，同时也要对接众多第三方保险中介渠道以及通过数字化手段赋能业务员团队等方式，以实现多渠道融合的互联网化改造任务及产品分销业务。'
},
{
    icon:'hexin',
    title:'核心中台',
    text:'鲁班云服中台核心系统能够在通过API调用保司核心系统的方式释放服务能力，赋能前台应用，满足复杂的前台业务场景需求。'
},
]
</script>

<style scoped>
.nav{
    height: 649px;
    position: relative;
}
.center{
    width: 1200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.card{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}
.t1{
    width: 380px;
    height: 300px;
    background: url('../../../../assets/img/home/1.png') no-repeat;
    background-size:100% 100%;
}
.t2{
    width: 380px;
    height: 300px;
    background: url('../../../../assets/img/home/2.png') no-repeat;
    background-size:100% 100%;
}
.t3{
    width: 380px;
    height: 300px;
    background: url('../../../../assets/img/home/3.png') no-repeat;
    background-size:100% 100%;
}
.t1 div,
.t2 div,
.t3 div{
    margin: 41px 0 0 20px;
}
.p1{
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 25px;
    margin-bottom: 18px;
}
.p2{
    height: 32px;
    background: #FFFFFF;
    border-radius: 6px;
    opacity: 0.7;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-bottom: 32px;
}
.p21{
    width: 240px;
    color: #384672;
}
.p22{
    width: 310px;
    color: #5E4195;
}
.p23{
    width: 198px;
    color: #BD2E2C;
}
.mask1{
    position: absolute;
    opacity: 0;
    left: 0;
    transition: opacity 1s;
}
.mask1:hover{
    opacity: 1;
}
.mask2{
    position: absolute;
    left: 34%;
    opacity: 0;
    transition: opacity 1s;
}
.mask2:hover{
    opacity: 1;
}
.mask3{
    position: absolute;
    right: 0;
    opacity: 0;
    transition: opacity 1s;
}
.mask3:hover{
    opacity: 1;
}
</style>