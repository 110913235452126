<template>
    <div class="nav">
        <img v-lazy='imgObj' alt="" class="lazy">
        <div class="eva">
        <p class="firstP">小能量 大爆发</p>
        <p class="secondP">专注于保险科技的创新和赋能数字化保险服务，助力企业一站式发展</p>
        <button @click="drup">
            开启企业提速模式
            <!-- <img src="../../../../assets/img/home/button_icon.png" alt=""> -->
            <img v-lazy="imgObj2" alt="">
        </button>
        </div>
    </div>
</template>

<script setup>
const imgObj = require('../../../../assets/img/home/banner.png')
const imgObj2 = require('../../../../assets/img/home/button_icon.png')
const drup = ()=>{
    window.location.href = 'https://www.wjx.cn/vm/rruZ6pb.aspx'
}
</script>

<style scoped>
.nav{
    position: relative;
    /* height: 500px;
    background: url('../../../../assets/img/home/banner.png') no-repeat;
    background-size:100% 100%; */
}
.lazy{
    height: 500px;
    width: 100%;
    vertical-align: bottom;
}
.eva{
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.firstP{
    /* width: 445px; */
    height: 93px;
    font-size: 68px;
    font-family: Alibaba-PuHuiTi-B, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 93px;
    margin-bottom: 16px;
}
.secondP{
    /* width: 600px;
    height: 28px; */
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: rgb(161, 161, 177);
    /* line-height: 28px; */
    margin-bottom: 40px;
}
.nav button{
    /* width: 200px; */
    height: 40px;
    background: linear-gradient(136deg, #FFFFFF 0%, #FFFFFF 100%);
    border-radius: 20px;
    border: none;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FF8A07;
    line-height: 22px;
    text-align: center;
    padding: 0 20px 0 20px;
}
.nav button img{
    margin-left: 5px;
}
.nav button:hover{
    background: #F5EDE5;
    cursor: pointer;
}

</style>