<template>
    <div class="nav">
        <div class="conxt">
            <TitleComponent :text='text'></TitleComponent>
            <div class="dev">
                <div class="left">
                    <!-- <img src="../../../../assets/img/home/gongnneg_img.png" alt=""> -->
                    <img v-lazy="imgObj" alt="">
                </div>
                <div class="right">
                    <Card v-for="(item,index) in data" :data='item' :key="index"></Card>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
import Card from '../../../../components/card.vue'
const imgObj = require('../../../../assets/img/home/gongnneg_img.png')
const text = '还是不够清楚？没关系，这是我们具体的功能概括'
const data = [
    {
        icon:'baodan',
        title:'保单管理',
        text:'保单信息审核、保单流程回溯/跟踪、续保、协助理赔等'
    },
    {
        icon:'chanp',
        title:'产品工厂',
        text:'3分钟自主上线配置，实现线上投保，支持个人非车险、商业保险配置'
    },
    {
        icon:'fenxiao',
        title:'分销管理',
        text:'对业务员/代理实行统一管理，收付/财务结算处理、基本法管理、多层分销设置等团队线上'
    },
    {
        icon:'shuju',
        title:'数据分析工具',
        text:'AI大数据30秒智能分析、用户画像分析，实现精准智能营销保'
    },
    {
        icon:'gexing',
        title:'个性化定制',
        text:'渠道接入平台、开放共享KPI，企业可自行定制任务计划、营销活动、迎流渠道投放等个性化需求'
    },
    {
        icon:'zhanye',
        title:'展业出单神器',
        text:'多款工具满足各类企业员工不用的展业需求，出单、报价、推广、管理一体化，轻松分享协同展业'
    },
]
</script>

<style scoped>
.nav{
    position: relative;
    height: 823px;
    background: #F7F7F9;
}
.conxt{
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.conxt .dev{
    margin-top: 60px;
    display: flex;
}
.conxt .left{
    width: 300px;
    height: 474px;
    border-radius: 10px;
    background: linear-gradient(330deg, #7979A6 0%, #4E4E78 100%);
}
.conxt .right{
    display: flex;
    width: 890px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 30px;

}
</style>