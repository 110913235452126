<template>
    <div class="nav">
        <div class="conxt">
        <TitleComponent :text='text'></TitleComponent>
        <div>
            <div class="left">
                <div class="text">
                    <!-- <img src="../../../../assets/img/home/logo_big.png" alt=""> -->
                    <img v-lazy="imgObj" alt="">
                    <p class="p1">秦保科技给您极致体验</p>
                    <p class="p2">专注于提供全球化格局的保险销售全链条SaaS系统服务，仅需2小时，即刻为客户打造出个性化品牌、产品及展业功能的专属PC端、移动端智能保险云平台</p>
                </div>
            </div>
            <div class="company">
                <ul>
                    <li v-for="item,index in imgUrl" :key="index">
                        <!-- <img :src="item" alt=""> -->
                        <img v-lazy="item" alt="" :key="item">
                    </li>
                </ul>
                <ul>
                    <li v-for="item,index in imgUrl" :key="index">
                        <!-- <img :src="item" alt=""> -->
                        <img v-lazy="item" alt="" :key="item">
                    </li>
                </ul>
            </div>
            <div class="right">
                <!-- <img src="../../../../assets/img/home/”.png" alt=""> -->
                <img v-lazy="imgObj2" alt="">
            </div>
        </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
import pa from '../../../../assets/img/home/pa@2x.png'
import tb from '../../../../assets/img/home/tb@2x.png'
import rb from '../../../../assets/img/home/rb@2x.png'
import qh from '../../../../assets/img/home/qh@2x.png'
import ha from '../../../../assets/img/home/ha@2x.png'
import gr from '../../../../assets/img/home/gr@2x.png'
import jd from '../../../../assets/img/home/jd@2x.png'
import my from '../../../../assets/img/home/my@2x.png'
import za from '../../../../assets/img/home/za@2x.png'
import fx from '../../../../assets/img/home/fx@2x.png'
import yat from '../../../../assets/img/home/yat@2x.png'
import gf from '../../../../assets/img/home/gf@2x.png'
import cim from '../../../../assets/img/home/cim@2x.png'
import pvi from '../../../../assets/img/home/pvi@2x.png'
import renqi from '../../../../assets/img/home/renqi@2x.png'
import renshou from '../../../../assets/img/home/renshou@2x.png'
const imgObj = require('../../../../assets/img/home/logo_big.png')
const imgObj2 = require('../../../../assets/img/home/”.png')
const text = '我们与很多顶级保险公司保持深度的合作'
const imgUrl = [pa,tb,rb,qh,ha,gr,renshou,pvi,jd,my,za,fx,yat,gf,renqi,cim]
</script>

<style scoped>
.nav{
    position: relative;
    height: 867px;
}
.nav .conxt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1200px;
}
.nav .left{
    width: 962px;
    height: 542px;
    background: url('../../../../assets/img/home/hezuo_bg.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 60px;
}
.nav .left .text{
    padding: 41px 0 0 51px;
}
.left .text .p1{
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    margin: 21px 0 5px 0;
    /* line-height: 48px; */
}
.left .text .p2{
    width: 842px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
}
.nav .conxt .company{
    width: 942px;
    height: 228px;
    position: absolute;
    top: 63%;
    overflow: hidden;
    margin-left: 6px;
}
.nav .conxt .company ul:nth-of-type(1){
    display: flex;
    width: 1440px;
    justify-content: space-between;
    flex-wrap: wrap;
    animation: scoll 20s linear infinite;
}
@keyframes scoll{
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-1440px);
    }
}
.nav .conxt .company ul:nth-of-type(2){
    position: absolute;
    top:0;
    left: 152%;
    display: flex;
    width: 1440px;
    justify-content: space-between;
    flex-wrap: wrap;
    animation: scoll2 20s linear infinite;
}
@keyframes scoll2{
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-1440px);
    }
}
.nav .conxt .company ul li{
    list-style: none;
}
.nav .conxt .company ul li img{
    width: 180px;
    height: 100px;
}
.nav .conxt .right{
    position: absolute;
    top: 25%;
    left: 88%;
}
</style>