<template>
    <div class="nav">
        <div class="conxt">
            <!-- <img :src="url" alt=""> -->
            <img v-lazy="url" alt="">
            <p class="p1">{{props.data.title}}</p>
            <p class="p2">{{props.data.text}}</p>
        </div>
    </div>
</template>

<script setup>
const props = defineProps(['data'])
let url
switch(props.data.icon){
    case 'kej':
        url = require('../assets/img/home/kej@2x.png')
        break;
    case 'hexin':
        url = require('../assets/img/home/hexin@2x.png')
        break;
    case 'shujuyunying':
        url = require('../assets/img/home/shujuyunying@2x.png')
        break;
}
</script>

<style scoped>
.nav{
    width: 380px;
    height: 300px !important;
    background: linear-gradient(138deg, rgba(13, 21, 44, 0.99) 0%, rgba(14, 23, 54, 0.79) 100%);
    border-radius: 10px;
}
.nav .conxt{
    margin: 26px 0 0 20px;
}
.nav .conxt img{
    width: 68px;
    height: 68px;
}
.nav .p1{
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    margin: 10px 0 13px 0;
}
.nav .p2{
    width: 340px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #848484;
}
</style>