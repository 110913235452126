<template>
    <div class="native">
        <span class="span1"><img :src="url" alt=""></span>
        <span class="span2">
            <p class="p1">{{props.data.title}}</p>
            <p class="p2">{{props.data.text}}</p>
        </span>
    </div>
</template>

<script setup>
const props = defineProps(['data'])
let url
switch(props.data.icon){
    case 'baodan':
        url = require('../assets/img/home/baodan@2x.png')
        break;
    case 'chanp':
        url = require('../assets/img/home/chanp@2x.png')
        break;
    case 'fenxiao':
        url = require('../assets/img/home/fenxiao@2x.png')
        break;
    case 'shuju':
        url = require('../assets/img/home/shuju@2x.png')
        break;
    case 'gexing':
        url = require('../assets/img/home/gexing@2x.png')
        break;
    case 'zhanye':
        url = require('../assets/img/home/zhanye@2x.png')
        break;
}
</script>

<style scoped>
.native{
    width: 420px;
    height: 138px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 30px;
    display: flex;
}
.span1{
    line-height: 165px;
    margin-left: 20px;
}
.span1 img{
    width: 47px;
    height: 47px;
}
.span2{
    margin: 30px 0 0 16px;
}
.span2 .p1{
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    margin-bottom: 5px;
}
.span2 .p2{
    width: 325px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #848484;
    line-height: 20px;
}
</style>