<template>
    <div class="nav">
      <img v-lazy="imgObj" alt="">
        <div class="conxt">
            <TitleComponent :text='text'></TitleComponent>
            <ul>
                <li v-for="item in dataList" :key="item.tip">
                    <span>{{item.textPrefix}}</span>
                    <CountTo 
                    :start='item.startNum' 
                    :end='item.endNumber'  
                    :suffix="item.suffix" 
                    :autoPlay="true" 
                    :duration='1000' 
                    />
                    <span>{{item.textsuffix}}</span>
                    <p class="tip">{{item.tip}}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
const imgObj = require('../../../../assets/img/home/shuju_bg.png')
const text = '我们是数百家保险公司的信赖之选'
const dataList = [
  {
    textPrefix: '为',
    textsuffix: '家',
    suffix: '',
    startNum: 0,
    endNumber: 300,
    tip:'保险从业公司提供场景化解决方案、系统功能服务'
  },
  {
    textPrefix: '共',
    textsuffix: '张',
    suffix: '+',
    startNum: 0,
    endNumber: 1000000,
    tip:'平台累计成交保单'
  },
  {
    textPrefix: '共',
    textsuffix: '元',
    suffix: '亿+',
    startNum: 0,
    endNumber: 1,
    tip:'平台累计保费'
  },
  {
    textPrefix: '与',
    textsuffix: '家',
    suffix: '+',
    startNum: 0,
    endNumber: 80,
    tip:'保险公司进行了系统接口打通，产品遍布全险种'
  },
  {
    textPrefix: '为',
    textsuffix: '个',
    suffix: '+',
    startNum: 0,
    endNumber: 400000,
    tip:'客户提供了风险保障'
  },
  {
    textPrefix: '',
    textsuffix: '款',
    suffix: '+',
    startNum: 0,
    endNumber: 800,
    tip:'平台累计上线产品'
  }
]
</script>

<style scoped>
.nav{
    position: relative;
    /* height: 881px; */
    /* background: url('../../../../assets/img/home/shuju_bg.png') no-repeat;
    background-size: 100% 100%; */
}
.nav img{
    height: 881px;
    width: 100%;
    vertical-align: bottom;
}
.conxt{
    position: absolute;
    width: 1200px;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
}
.nav .conxt ul{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 1113px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 174px 0 0 87px;
}
.nav .conxt ul li{
    width: 28%;
    list-style: none;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    margin-bottom: 70px;
}
.nav .conxt ul li span:nth-of-type(1),
.nav .conxt ul li span:nth-of-type(2){
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
}
.nav .conxt ul li .tip{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #848484;
    margin-top: 4px;
}
</style>