<template>
    <div class="nav">
        <div class="conxt">
            <div class="left">
                <TitleComponent :text='text'></TitleComponent>
                <p>这是超能保智能展业平台，更多工具等你体验～<br/>让我们做好准备更好的迎接保险行业数字化的到来。</p>
                <!-- <img src="../../../../assets/img/home/erweima.png" alt=""> -->
                <img v-lazy="imgObj" alt="">
            </div>
            <div class="right">
                <img v-lazy="imgObj2" alt="">
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
const imgObj = require('../../../../assets/img/home/erweima.png')
const imgObj2 = require('../../../../assets/img/home/pingtai@2x.png')
const text = '懂你所需 护你未来'
</script>

<style scoped>
.nav{
    position: relative;
    height: 842px;
}
.conxt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    width: 1200px;
    display: flex;
    justify-content: space-between;
}
/* .left{
    width: 32%;
} */
.left p{
    font-size: 16px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #848484;
    margin: 11px 0 53px 0;
}
.right img{
    width: 698px;
    height: 471px;
    /* background: url('../../../../assets/img/home/pingtai.png') no-repeat;
    background-size: 100% 100%; */
    /* margin-left: 122px; */
}
</style>